<template>
  <div class="wrapper">
    <!--<single-page-breadcrumbs></single-page-breadcrumbs>-->
    <div class="centered-wrapper">
      <h2>Thank you for your feedback</h2>
      <p>We’ll use this to improve the way we communicate with you.</p>
    </div>
    <single-page-footer />
    <!--<cookie-policy></cookie-policy>-->
  </div>
</template>

<script>
import store from '../store';

import SinglePageFooter from '@/components/SinglePageFooter';
//import CookiePolicy from '@/components/CookiePolicy';
//import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';

export default {
  name: 'ViewThankYouFeedback',
  components: {
     SinglePageFooter,
     //CookiePolicy,
     //SinglePageBreadcrumbs,
  },
  data() {
    return {
      breadcrumbnav: 'Root'
    }
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch('setSearchStatus', false); // reset search status
    next();
  },

  beforeRouteUpdate(to, from, next) {
    store.dispatch('setSearchStatus', false); // reset search status
    next();
  },

  computed: {
    isRequestFinished() {
      return store.state.isAjaxDone;
    },

    init() {
      return store.state.init;
    },

    engineInit() {
      return store.state.engineInit;
    },

    response() {
      return store.state.responseData;
    }
  }
}
</script>

<style scoped>

  .wrapper {
    min-height: 500px;
    position: relative;
    max-width: 930px;
    margin: 0 auto 0 auto;
  }

  h2 {
    font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */ 
    color: #333; 
    margin-bottom: 0px;
  }

  p {
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headings/S4 */
    font-family: 'LBJ Regular';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.4px; 
    color: #333; 
  }

  .centered-wrapper {
    padding-top: 10%;
    padding-bottom: 10%;
  }


</style>