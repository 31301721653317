<template>
    <div class="va-not-found-page">
        <div class="wrapper">
            <!--<single-page-breadcrumbs></single-page-breadcrumbs>-->
            <h2>Page not found</h2>
            <div class="not-found-panel">
                <h4>Sorry, the page you're looking for can't be found.</h4>
                <template v-if="isRequestFinished && init && engineInit">
                    <main-page-faq-selector :faqs="response.data.questions"></main-page-faq-selector>
                </template>
            </div>
            <single-page-footer></single-page-footer>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import store from '../store';

import SinglePageFooter from '@/components/SinglePageFooter';
//import CookiePolicy from '@/components/CookiePolicy';
//import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';
import MainPageFaqSelector from '@/components/MainPageFaqSelector';

export default {
    name: 'ViewNotFoundPage',
    components: {
       SinglePageFooter,
       //CookiePolicy,
       //SinglePageBreadcrumbs,
       MainPageFaqSelector
    },
    data() {
        return {
            breadcrumbnav: 'Root'
        }
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next();
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next();
    },

    computed: {
        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        init() {
            return store.state.init;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response() {
            return store.state.responseData;
        }
    }
}
</script>

<style scoped>

    .va-not-found-page {
        background: #f9f9f9;
    }

    .wrapper {
        min-height: 500px;
        position: relative;
        max-width: 930px;
        margin: 0 auto 0 auto;
    }

    h2 {
        font-size: 2.5em;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        font-weight: normal;
        color: #323233;
        padding: 30px 0 30px 0;
        margin: 0;
    }

    .not-found-panel {
        width: 100%;
        padding: 30px;
        background: #ffffff;
        margin: 0 0 30px 0;
        box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);
    }

    .not-found-panel h4 {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        color: #323233;
        font-size: 1.375em;
        /*
        border-bottom: 1px solid #BFBFBF;
        padding: 0 0 15px 0;
        */
        margin: 0 0 5px 0;
        font-weight: normal;
    }

    .not-found-panel a {
        font-size: 1.125em;
    }

    .not-found-panel p {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        color: #505050;
        font-size: .9375em;
        margin: 0 0 30px 0;
    }

    .not-found-panel a:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: translateY(-50%) rotate(45deg);
        color: #006A4D;
        margin: 0 0 -4px 5px;
    }

</style>