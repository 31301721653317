<template>
    <div class="update" v-if="showNotification">
        <div class="wrapper">
            <div v-html="whatsnew"></div>
            <button class="update-details" @click.prevent="goTo('/whats-new')">Find out more</button>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'SinglePageUpdate',
    methods: {
        goTo(url) {
            router.push({path: url})
        }
    },
    computed: {
        whatsnew() {
            return store.state.responseData.data.etv.notification_whatnew;
        },

        showNotification() {
            if(this.whatsnew == 'empty') {
                return false;
            }
            else {
                return true;
            }
        }
    }
}
</script>

<style scoped>
    .update {
        max-width: 930px;
        margin: 48px auto 0 auto;
        box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);
        padding: 24px;
        position: relative;
    }

    .update >>> h4 {
        margin: 0 0 5px 0;
        padding: 0 160px 0 0;
        font-weight: normal;
        font-size: 1.375em;
        color: #323233;
    }

    .update >>> h4 span {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .update >>> p {
        margin: 0;
        padding: 0 160px 0 0;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        font-size: .9375em;
        color: #505050;
    }

    .update >>> .update-details {
        display: block;
        border: 2px solid #006A4D;
        background: none;
        padding: 12px 18px;
        font-size: 1.125em;
        color: #006A4D;
        transition: all .2s;
        margin: 0 0 0 0;

        position: absolute;
        right: 24px;
        top: 40px;     
    }

    .update >>> .update-details:hover {
        color: #ffffff;
        background: #006A4D;
        cursor: pointer;
    }

    .update >>> .update-details:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: rotate(45deg);
        color: #006A4D;
        margin: 0 0 0 5px;
        transition: all .2s;  
    }

    .update >>> .update-details:hover:after {
        color: #ffffff;
    }

    @media only screen and (max-width: 966px) {
        .update {
            margin: 48px 18px 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {

        .update >>> h4, .update >>> p {
            padding: 0;
        }

        .update >>> .update-details {
            position: static;
            margin: 24px 0 0 0;
        }
    }

</style>
