<template>
	<div class="loading">
		<div class="spinner"></div>
		<!--
		<div class="cssload-square">
			<div class="cssload-square-part cssload-square-color1"></div>
			<div class="cssload-square-part cssload-square-color2"></div>
			<div class="cssload-square-blend"></div>
		</div>
		-->
	</div>
</template>

<script>
export default {
    name: 'MainAjaxSpinner'
}
</script>

<style scoped>

	.loading {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 100;
	}

	.spinner {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 110;
		background-image: url('./../assets/images/spinner.gif');
		width: 60px;
		height: 60px;
		margin: auto;
	}

	/*
	.loading {
		bottom: 0;
		height: 19px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 19px;
		z-index: 100;
		background: #ffffff;
	}

	.cssload-square {
		margin: auto;
		width: 19px;
		height: 19px;
		transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
	}

	.cssload-square-part {
		position: absolute;
		width: 19px;
		height: 19px;
		z-index: 1;
		animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
			-o-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
			-ms-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
			-webkit-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
			-moz-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
	}

	.cssload-square-color1 {
		background: #006A4D;
		right: 0;
		bottom: 0;
		animation-direction: alternate-reverse;
			-o-animation-direction: alternate-reverse;
			-ms-animation-direction: alternate-reverse;
			-webkit-animation-direction: alternate-reverse;
			-moz-animation-direction: alternate-reverse;
	}

	.cssload-square-color2 {
		background: #2D8259;
		left: 0;
		top: 0;
	}

	.cssload-square-blend {
		background: #333333;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 2;
		animation: blend-anim 0.92s ease-in infinite;
			-o-animation: blend-anim 0.92s ease-in infinite;
			-ms-animation: blend-anim 0.92s ease-in infinite;
			-webkit-animation: blend-anim 0.92s ease-in infinite;
			-moz-animation: blend-anim 0.92s ease-in infinite;
	}

	@keyframes blend-anim {
		0% {
			transform: scale(0.01, 0.01) rotateY(0);
			animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
		}
		50% {
			transform: scale(1, 1) rotateY(0);
			animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		100% {
			transform: scale(0.01, 0.01) rotateY(0);
		}
	}

	@-o-keyframes blend-anim {
		0% {
			-o-transform: scale(0.01, 0.01) rotateY(0);
			-o-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
		}
		50% {
			-o-transform: scale(1, 1) rotateY(0);
			-o-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		100% {
			-o-transform: scale(0.01, 0.01) rotateY(0);
		}
	}

	@-ms-keyframes blend-anim {
		0% {
			-ms-transform: scale(0.01, 0.01) rotateY(0);
			-ms-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
		}
		50% {
			-ms-transform: scale(1, 1) rotateY(0);
			-ms-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		100% {
			-ms-transform: scale(0.01, 0.01) rotateY(0);
		}
	}

	@-webkit-keyframes blend-anim {
		0% {
			-webkit-transform: scale(0.01, 0.01) rotateY(0);
			-webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
		}
		50% {
			-webkit-transform: scale(1, 1) rotateY(0);
			-webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		100% {
			-webkit-transform: scale(0.01, 0.01) rotateY(0);
		}
	}

	@-moz-keyframes blend-anim {
		0% {
			-moz-transform: scale(0.01, 0.01) rotateY(0);
			-moz-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
		}
		50% {
			-moz-transform: scale(1, 1) rotateY(0);
			-moz-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		100% {
			-moz-transform: scale(0.01, 0.01) rotateY(0);
		}
	}

	@keyframes cssload-part-anim {
		0% {
			transform: translate3d(-10px, -10px, 0);
		}
		100% {
			transform: translate3d(10px, 10px, 0);
		}
	}

	@-o-keyframes cssload-part-anim {
		0% {
			-o-transform: translate3d(-10px, -10px, 0);
		}
		100% {
			-o-transform: translate3d(10px, 10px, 0);
		}
	}

	@-ms-keyframes cssload-part-anim {
		0% {
			-ms-transform: translate3d(-10px, -10px, 0);
		}
		100% {
			-ms-transform: translate3d(10px, 10px, 0);
		}
	}

	@-webkit-keyframes cssload-part-anim {
		0% {
			-webkit-transform: translate3d(-10px, -10px, 0);
		}
		100% {
			-webkit-transform: translate3d(10px, 10px, 0);
		}
	}

	@-moz-keyframes cssload-part-anim {
		0% {
			-moz-transform: translate3d(-10px, -10px, 0);
		}
		100% {
			-moz-transform: translate3d(10px, 10px, 0);
		}
	}
	*/

</style>
