<template>
    <div class="page">
        <div class="wrapper">
            <template v-if="showError">
                <main-modal-window :type="'error'"></main-modal-window>
            </template>
            <template v-else>
                <div v-if="isRequestFinished && init && engineInit">
                    <single-page-search></single-page-search>
                    <single-page-notification :notification="response.data.etv.notification" :view="'clean'"></single-page-notification>
                    <main-page-maincat-selector></main-page-maincat-selector>
                    <single-page-update></single-page-update>
                    <main-page-faq-selector :faqs="response.data.questions"></main-page-faq-selector>
                    <single-page-footer :view="'clean'"></single-page-footer>
                </div>
                <div v-else>
                    <main-ajax-spinner></main-ajax-spinner>
                </div>
            </template>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';

import SinglePageSearch from '@/components/SinglePageSearch';
import MainPageMaincatSelector from '@/components/MainPageMaincatSelector';
import SinglePageFooter from '@/components/SinglePageFooter';
import MainPageFaqSelector from '@/components/MainPageFaqSelector';
import SinglePageNotification from '@/components/SinglePageNotification';
import MainAjaxSpinner from '@/components/MainAjaxSpinner';
import MainModalWindow from '@/components/MainModalWindow';
//import CookiePolicy from '@/components/CookiePolicy';
import SinglePageUpdate from '@/components/SinglePageUpdate';
import tealium from '../mixins/tealium.js';

export default {
    name: 'ViewMainPage',
    mixins: [tealium],
    data() {
        return {
            showError: false,
            debug: false,
        }
    },

    components: {
        SinglePageSearch,
        MainPageMaincatSelector,
        SinglePageFooter,
        MainPageFaqSelector,
        MainAjaxSpinner,
        SinglePageNotification,
        MainModalWindow,
        //CookiePolicy,
        SinglePageUpdate
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next(vm => {
            window.scrollTo(0, 0);
            vm.tealiumRouteTracking(to);
        });
        next();
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        this.tealiumRouteTracking(to);
        next();
    },

    beforeRouteLeave(to, from, next) {
        store.dispatch('setAutocompleteResponseData', null);
        next();
    },

    watch: {
        init(n) {
            this.debug && console.log('e02');
            n && Event.$emit('va.request', {type: 'init'});
        },

        $route(to, from) {
            this.debug && console.log('e03');
            Event.$emit('va.request', {});
        },

        error(n) {
            n.show ? (this.showError = true) : (this.showError = false);
        }
    },

    methods: {
        isSessionValid() {
            let valid = false;
            let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
            
            if(session && this.token.length > 0) {
                valid = true;
            }
            else {
                this.debug && console.log('e01');
                Event.$emit('va.init', {init: 1});
            }

            return valid;
        },
    },

    created() {
        if(this.isSessionValid()) {
            this.debug && console.log('e04');
            Event.$emit('va.request', {type: 'catnav', category: 'Root'});
        }
    },

    computed: {
        init() {
            return store.state.init;
        },

        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response () {
            return store.state.responseData;
        },

        token() {
            return store.state.token;
        },

        error() {
            return store.state.error;
        }
    }
}
</script>

<style scoped>

    .wrapper {
        min-height: calc(100vh - 240px);
        position: relative;
    }

</style>