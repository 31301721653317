<template>
    <div class="global-survey gs-survey" :class="{active: open}" v-if="offerSurvey">

        <div class="gs-trigger" role="button" aria-label="Open the feedback survey" @click.prevent="changeState(true)">
            <h2 class="gs-trigger-header">Feedback</h2>
        </div>

        <div class="gs-body">
            <h2 class="gs-header">Feedback</h2>
            <button class="gs-close" aria-label="Close the survey" @click.prevent="changeState(false)">Close</button>

            <div class="gs-options">

                <div class="gs-option">
                    <p>Did you find what you need today? <span class="attention" v-if="!opt1valid">*</span></p>
                    <div class="gs-user-select">
                        <input type="radio" value="Yes" name="gs-option1" id="gs-option1-1" v-model="opt1">
                        <label for="gs-option1-1">Yes</label>
                    </div>
                    <div class="gs-user-select">
                        <input type="radio" value="No" name="gs-option1" id="gs-option1-2" v-model="opt1">
                        <label for="gs-option1-2">No</label>
                    </div>
                </div>

                <div class="gs-option">
                    <p>
                        What were you looking for?<br>
                        <span>(do not provide any details about your account)</span>
                    </p>
                    <textarea maxlength="150" v-model="opt3"></textarea>
                </div>

                <div class="gs-option">
                    <p>Will you call us for further help with this? <span class="attention" v-if="!opt2valid">*</span></p>
                    <div class="gs-user-select">
                        <input type="radio" value="Yes" name="gs-option2" id="gs-option2-1" v-model="opt2">
                        <label for="gs-option2-1">Yes</label>
                    </div>
                    <div class="gs-user-select">
                        <input type="radio" value="No" name="gs-option2" id="gs-option2-2" v-model="opt2">
                        <label for="gs-option2-2">No</label>
                    </div>
                </div>

                <button data-tealium-tracking="full" data-tealium-narrative="Global Survey" class="gs-submit" @click.prevent="submitSurvey">Submit</button>
            </div>

            <div class="overlay" v-if="submitted">
                <h3>Thank you for your feedback</h3>
            </div>
        </div>
    </div>    
</template>

<script>
import {Event} from "../event";
import store from '../store';
import tealium from '../mixins/tealium.js';

export default {
    name: 'GlobalSurvey',
    mixins: [tealium],
    data() {
        return {
            open: false,
            hasOpened: false,
            submitted: false,
            opt1: '',
            opt1valid: true,
            opt2: '',
            opt2valid: true,
            opt3: '',
            opt3valid: true,
            offerSurvey: false,
            hasOffered: false
        }
    },

    created: function() {
        if(this.surveyState) {
            //console.log(1);
            //if(localStorage.getItem('surveyOffered') !== null/* || localStorage.getItem('surveyOffered') == 'true'*/) {
                if(localStorage.getItem('surveySubmitted') === null || this.getTimeOffset(+ new Date(), localStorage.getItem('surveySubmitted') >= 30)) {
                    console.log(2);
                    this.showSurvey();
                }
            //}
        }

        if(this.init && localStorage.getItem('surveyOffered') === null) {
            Event.$emit('va.survey', {});
        }

       /*
       console.log(0);
        this.init && store.dispatch('setSurveyState', true);
        */
    },

    watch: {
        init(n) {
            if(n && localStorage.getItem('surveyOffered') === null) {
                Event.$emit('va.survey', {});
            }
           /*
           console.log(3);
            n && Event.$emit('va.survey', {});
            */
        },

        surveyState(n) {
            if(n) {
                if(localStorage.getItem('surveySubmitted') === null || this.getTimeOffset(+ new Date(), localStorage.getItem('surveySubmitted') >= 30)) {
                    this.showSurvey();
                }
            }

           /*
            console.log(4);
            n && this.showSurvey();
            */
        }
    },

    computed: {
        init() {
            return store.state.init;
        },

        surveyState() {
            if(localStorage.getItem('surveyOffered') !== null) {
                return true;
            }
            else {
                return store.state.survey;
            }
        }
    },

    methods: {

        showSurvey() {
            let that = this;
            setTimeout(function() {
                that.offerSurvey = true;

                // we submit 'offered' data to Tealium once
                if(!that.hasOffered) {
                    that.tealiumGlobalSurveyTracking('offer', that.$route, {});

                    //we also set the flag to local storage that the survey has been offered
                    localStorage.setItem('surveyOffered', true);

                    localStorage.removeItem('surveySubmitted');

                    that.hasOffered = true;
                }

            }, 900);
        },

        changeState(state) {
            // we submit 'opened' data to Tealium once
            if(state && !this.hasOpened) {
                this.tealiumGlobalSurveyTracking('open', this.$route, {});
                this.hasOpened = true;
            }

            // hide the suvey dialog once it has been submitted
            if(this.submitted) {
                this.offerSurvey = false;
            }

            this.open = state;
        },

        getTimeOffset(t1, t2) {
            let difference = t1 - t2;
            let daysDifference = Math.floor(difference/60/60/24);
            return daysDifference;
        },

        submitSurvey() {
            let valid = true;

            this.opt1.length == 0 ? (this.opt1valid = false, valid = false) : this.opt1valid = true;
            this.opt2.length == 0 ? (this.opt2valid = false, valid = false) : this.opt2valid = true;

            if(valid) {
                this.submitted = true;
                localStorage.setItem('surveySubmitted', + new Date());
                localStorage.removeItem('surveyOffered');
                

                let surveyData = {
                    opt1: {
                        title: "Did you find what you need today?",
                        data: this.opt1
                    },
                    opt2: {
                        title: "Will you call us for further help with this?",
                        data: this.opt2
                    },
                    opt3: {
                        title: "What were you looking for?",
                        data: this.opt3
                    },
                };

                this.tealiumGlobalSurveyTracking('submit', this.$route, surveyData);
            }
            else {
                return false;
            }
        }
    }
}
</script>

<style scoped>


    @keyframes windowfade {
        from {
            transform: translateY(20px);
            opacity: 0;
            display: none;
        }

        to {
            transform: translateY(0);
            opacity: 1;
            display: block;
        }
    }



    .gs-trigger {
        width: 176px;
        height: 56px;
        background-color: #2178b0;
        position: fixed;
        right: 20px;
        bottom: 20px;
        display: block;
        box-shadow: 0 0 5px 0 rgb(173 173 173 / 50%);
    }

    .active .gs-trigger {
        display: none;
    }

    .gs-trigger:hover {
        cursor: pointer;
    }

    .gs-body {
        width: 340px;
        height: 490px;
        background-color: #fff;
        position: fixed;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: inset 0 56px 0 0 #006a4d, 0 0 5px 0 rgb(173 173 173 / 50%);

        display: none;
        opacity: 0;
    }

    .active .gs-body {
        display: block;
        opacity: 1;


        animation-timing-function: ease-in-out;
        animation: windowfade .6s;
    }


    h2 {
        line-height: 56px;
        position: relative;
        color: #fff;
        margin: 0 0 0 70px;
        padding: 0;
        font-weight: normal;
        font-family: 'LBJ Regular', Arial, sans-serif;
        font-size: 1.25em;
    }

    h2::before {
        content: '';
        background-image: url('./../assets/images/pencil.svg');
        width: 28px;
        height: 33px;
        position: absolute;
        left: -50px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    
    p {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
    }

    p span {
        color: #767676;
        font-family: 'LBJ Regular', Arial, sans-serif !important;
        font-size: .9375em;
    }



    .gs-close {
        position: absolute;
        right: 20px;
        top: 13px;
        width: 32px;
        height: 32px;
        z-index: 190;
        text-indent: -9999px;
        background: none;
        border: none;
    }

    .gs-close:hover {
        cursor: pointer;
    }

    .gs-close:after, .gs-close:before {
        position: absolute;
        right: 15px;
        content: ' ';
        height: 32px;
        width: 2px;
        background: #fff;
        top: 0;
    }

    .gs-close:before {
        transform: rotate(45deg);
    }

    .gs-close:after {
        transform: rotate(-45deg);
    }



    .gs-options {
        padding: 0 20px 0 20px;
    }

    .gs-option {
        padding: 0 0 15px 0;
        border-bottom: 1px solid #e1e1e1;
    }

    .gs-option:last-of-type {
        border: none;
        padding: 0;
    }

    .gs-user-select {
        display: inline-block;
        margin: 0 40px 0 0;
    }

    input[type="checkbox"], input[type="radio"] {
        padding: 0;
        margin: 0;
        border: none;
        -webkit-appearance: none;
    }

    [type=radio]:not(:checked), [type="radio"]:checked {
        position: absolute;
        left: 0;
        opacity: 0.01;
    }

    [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
        position: relative;
        padding-left: 43px !important;
        line-height: 2;
        cursor: pointer;
        display: block;
    }

    /* checkbox aspect */
    [type="radio"]:not(:checked) + label:before, [type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border: 1px solid #767676;
        background: #FFF;
        border-radius: 100%;
        -webkit-transition: all .275s;
        transition: all .275s;
    }

    /* checked mark aspect */
    [type="radio"]:not(:checked) + label:after, [type="radio"]:checked + label:after {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        top: 7px;
        left: 7px;
        font-size: 2.5em;
        background: #00847F;
        border-radius: 100%;
        -webkit-transition: all .2s;
        transition: all .2s;
    }

    /* checked mark aspect changes */
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }




    textarea {
        width: 100%;
        height: 40px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        resize: none;
    }

    .gs-submit {
        display: block;
        background: #006A4D;
        padding: 12px 18px;
        transition: all .2s;
        border: 2px solid #006A4D;
        color: #fff;
        margin: 15px 0 0 0;
    }

    .gs-submit:hover {
        cursor: pointer;
    }

    .overlay {
        position: absolute;
        background: #fff;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    h3 {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        color: #006a4d;
        font-weight: normal;
        text-align: center;
        font-size: 2em;
        margin: 120px 0 0 0;
    }

    .attention {
        color: #ff0000;
    }



</style>
