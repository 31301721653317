<template>
    <div class="quick-links">
        <h3>Quick links</h3>
        <!--
        <ul>
            <li><a href="#" title="">First time to Commercial Banking online</a></li>
            <li><a href="#" title="">My activation code has expired</a></li>
            <li><a href="#" title="">I want to create a user</a></li>
            <li><a href="#" title="">I want to create a payment</a></li>
            <li><a href="#" title="">What are the available payment types</a></li>
        </ul>
        -->
        <ul v-if="faqs.length > 0">
            <li v-for="(item, index) in faqs" :key="index">
                <a :href="item.link" :title="item.question">{{item.question}}</a>
            </li>
        </ul>  
    </div>
</template>

<script>
export default {
    name: 'MainPageFaqSelectorQuickLinks',
    props: ['faqs'],
}
</script>

<style scoped>

    .quick-links {
        width: 49%;
        position: relative;
    }

    .quick-links h3 {
        font-size: 1.375em;
        margin: 40px 0 25px 0;
    }

    .quick-links ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.125em;
    }

    .quick-links ul li a {
        padding: 0 0 10px 0;
        display: block;
        /*position: relative;*/
    }

    .quick-links ul li a:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: translateY(-50%) rotate(45deg);
        color: #006A4D;
        margin: 0 0 -3px 10px;
        /*
        position: absolute;
        top: 12px;
        */
    }

    @media only screen and (max-width: 650px) {
        .quick-links {
            width: 100%;
            margin-bottom: 30px;
        }
    }

</style>
