<template>
    <div class="main-header">
        <div class="wrapper">
            <a href="/" class="main-logo" title="Lloyds Bank" @click.prevent="goHome">Lloyds Bank</a>
            <a href="https://cbonline.lloydsbank.com/PrimaryAuth/" class="login" target="_blank" rel="noopener" title="Log on">Log on</a>
            <h1>Commercial Banking Online <span>Help Centre</span></h1>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'MainHeader',

    computed: {
        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    },

    methods: {
        goHome() {
            if(this.isCustomPage) {
                return false;
            }
            else {
                router.push({ path: '/'});
            }
        }
    }
}

</script>

<style scoped>
    
    .main-header {
        background: #006A4D;
    }

    .wrapper {
        height: 96px;
    }

    h1 {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        color: #ffffff;
        text-transform: uppercase;
        font-size: .75em;
        position: absolute;
        right: 130px;
        width: 210px;
        text-align: right;
        top: 34px;
        margin: 0;
        font-weight: 500;
        letter-spacing: 2.02px;
        padding: 0;
        line-height: 14px;
    }

    h1 span {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
    }

    .main-logo {
        display: block;
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: 0 0;
        background-size: 300px 300px;
        width: 210px;
        height: 52px;
        top: 0;
        bottom:0;
        margin: auto;
        position: absolute;
    }

    .login {
        background: #00553E;
        height: 36px;
        width: 116px;
        line-height: 38px;
        color: #ffffff;
        position: absolute;
        text-decoration: none;
        display: block;
        padding: 0 0 0 38px;
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        font-weight: 500;
        font-size: .875em;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all .2s;
    }

    .login:hover {
        background: #004431;
        text-decoration: underline;
    }

    .login:before {
        content: '';
        display: block;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: -50px -150px;
        background-size: 300px 300px;
        width: 14px;
        height: 14px;
        top: 0;
        bottom:0;
        left: 18px;
        margin: auto;
        position: absolute;

    }

    .login:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 5px;
        width: 5px;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
        margin: 0 0 -4px 8px;
    }

    @media only screen and (max-width: 966px) {
        .main-header {
            padding: 0 18px 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        .wrapper {
            height: 72px;
        }

        .main-logo {
            background-position: -157px 0;
            width: 53px
        }

        h1 {
            top: 22px;
            right:200px;
        }

        .login {
            right: 70px;
        }

    }

    @media only screen and (max-width: 500px) {
        h1 {
            top: 22px;
            right: 200px;
            font-size: .5em;
            width: 140px;      
        }
    }

    @media only screen and (max-width: 430px) {
        h1 {
            width: 300px;
            right: 70px;
            top: 56px;
        }
    }

    @media only screen and (max-width: 410px) {
        h1 {
            display: none;
        }
    }
    
</style>
