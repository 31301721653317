<template>
    <div class="main-menu" :class="{mob: mobile.is}">
        <template v-if="mobile">
            <button class="mob-menu-trigger" :class="{active: isMobMenuActive}" @click.prevent="triggerMobileMenu">Menu</button>
        </template>
        <div class="wrapper" v-show="isMobMenuActive || !mobile.is">
            <main-menu-navigation :mob="mobile"></main-menu-navigation>
            <main-menu-search-field :mob="mobile"></main-menu-search-field>
        </div>
        <main-banner style="display:none"></main-banner>
        <!--<main-banner-webinars></main-banner-webinars>-->
    </div>
</template>

<script>
import store from '../store';

import MainMenuNavigation from '@/components/MainMenuNavigation';
import MainMenuSearchField from '@/components/MainMenuSearchField';
import MainBanner from '@/components/MainBanner';
//import MainBannerWebinars from '@/components/MainBannerWebinars';

export default {
    name: 'MainMenu',
    components: {
        MainMenuNavigation,
        MainMenuSearchField,
        MainBanner
        //MainBannerWebinars
    },
    data() {
        return {
            mobileThreshold: 651,
            mobile: {
                is: window.innerWidth < 651 ? true : false,
                width: window.innerWidth,
                height: window.innerHeight
            },
            mobMenuActive: false,
        }
    },

    watch: {
        isMobMenuActive() {
            this.mobMenuActive = this.isMobMenuActive;
        }
    },

    methods: {
        isMobile() {
            let width = window.innerWidth;
            let height = window.innerHeight;

            this.mobile.is = width < this.mobileThreshold ? true : false;
            this.mobile.width = width;
            this.mobile.height = height;
        },

        triggerMobileMenu() {
            this.mobMenuActive = !this.mobMenuActive;
            store.dispatch('setMobMenuStatus', this.mobMenuActive);
        }
    },

    mounted() {
        window.addEventListener('resize', this.isMobile);
    },

    computed: {
        isMobMenuActive: function() {
            return store.state.mobMenuStatus;
        }
    }
}

</script>

<style scoped>
    
    .main-menu {
        background: #2D8259;
        position: relative;
    }

    .mob-menu-trigger {
        display: none;
        position: absolute;
        right: 0;
        top: -72px;
        width: 72px;
        height: 72px;
        color: #ffffff;
        border: none;
        background: #006A4D;
    }

    .mob-menu-trigger:after {
        content: '';
        left: 0;
        top: 12px;
        bottom: 12px;
        position: absolute;
        width: 1px;
        background: rgba(255,255,255,0.2);
    }

    .mob-menu-trigger:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 650px) {
        .mob-menu-trigger {
            display: block;
        }

        .mob-menu-trigger.active {
            background: #ffffff;
            color: #2D8259;
        }
    }

</style>
