<template>
    <div class="notification" :class="[view]" v-if="this.showNotification">
        <div class="wrapper">
            <single-page-notification-item v-if="this.checkDisplay('notification_negative') && notification != 'empty'" :message="notification" :type="'negative'"></single-page-notification-item>
            <!--<single-page-notification-item v-if="true" :message="'This is a test'" :type="'negative'"></single-page-notification-item>-->
            <single-page-notification-item v-if="this.checkDisplay('notification_browser') && this.detectIE()" :message="this.browser.message" :type="this.browser.type"></single-page-notification-item>
        </div>
    </div>
</template>

<script>
import SinglePageNotificationItem from '@/components/SinglePageNotificationItem';

export default {
    name: 'SinglePageNotification',
    props: ['notification', 'view'],
    components: {
        SinglePageNotificationItem,
    },
    data() {
        return {
            browser: {
                type: 'browser',
                message: 'Your web browser is out of date. Update your browser for more security, speed and the best experience on this site.',
            }
        }
    },
    computed: {
        showNotification() {
            if(this.notification != 'empty' || this.detectIE()) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    methods: {
        checkDisplay: function(type) {
            if(sessionStorage[type] && sessionStorage[type] == 0) {
                return false;
            }
            else {
                return true;
            }
        },

        detectIE: function() {
            var ua = window.navigator.userAgent;

            // Test values; Uncomment to check result …

            // IE 10
            // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
            
            // IE 11
            // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
            
            // Edge 12 (Spartan)
            // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
            
            // Edge 13
            // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                return true;
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                //return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                return false;
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                //return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                return false;
            }

            // other browser
            return false;
        }
    }
}
</script>

<style scoped>
    .notification {
        background: #EEF4E6;
        margin: 15px 0 15px 0;
    }

    .notification.clean {
        margin: 0;
        padding: 15px 18px 15px 18px;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        /*padding: 0 0 15px 0;*/        
    }

    .message:last-child {
        margin-bottom: 0;
    }

    /*
    @media only screen and (max-width: 650px) {
        .notification {
            margin: 15px 0 15px 0 !important;
        }
    }
    */
    

</style>
