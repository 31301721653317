export default {
    data () {
        return {
            log: false
        }
    },
    methods: {

        tealiumRouteTracking(route, status) {
            //this.log && console.log('[Tealium Route Tracking]');
            //console.log(status);
            this.log && console.log('[TRACKING ROUTE]: %o', route);

            let l1 = typeof route.params.cat1 != 'undefined' ? true : false;
            let l2 = typeof route.params.cat2 != 'undefined' ? true : false;
            let l3 = typeof route.params.cat3 != 'undefined' ? true : false;
            let article = typeof route.params.articletitle != 'undefined' ? true : false;
            let q = (Object.keys(route.query).length === 0 && route.query.constructor === Object) ? false : true;
            let qData = (arguments.length > 1) ? true : false;

            let persist_data = {};

            let jname = '';
            let jstep = 0;
            let jStepName = '';
            let appState = 'Product Information';
            let prodFamily = '';
            let prodGroup = '';
            let prodSubGroup = '';

            l1 && (
                jstep = 1,
                jStepName = route.params.cat1.replace(/-/g, ' '),
                jname = route.params.cat1.replace(/-/g, ' '),
                prodFamily = route.params.cat1.replace(/-/g, ' ')
            );
            
            l2 && (
                jstep = 2,
                jStepName = route.params.cat2.replace(/-/g, ' '),
                prodGroup = route.params.cat2.replace(/-/g, ' ')
            );

            l3 && (
                jstep = 3,
                jStepName = route.params.cat3.replace(/-/g, ' '),
                prodGroup = route.params.cat3.replace(/-/g, ' ')
            );

            article && (
                jstep = 4,
                //jStepName = route.params.articletitle.replace(/-/g, ' '),
                jStepName = status.answerData.title.toLowerCase(),
                //prodGroup = route.params.cat3.replace(/-/g, ' '),
                l3 && (prodGroup = route.params.cat3.replace(/-/g, ' ')),
                l2 && (prodGroup = route.params.cat2.replace(/-/g, ' ')),
                l1 && (prodGroup = route.params.cat1.replace(/-/g, ' ')),
                //prodSubGroup = route.params.articletitle.replace(/-/g, ' ')
                prodSubGroup = status.answerData.title.toLowerCase(),

                (status.ref == 'Nav' && (persist_data.SearchLocation = status.ref)) // we need to append "Nav" when user clicks on semantic FAQ
            );

            if(q && qData) {
                jstep = '';
                let fullPath = status.answerData.maincat.split('.');
                
                if(status.sn && status.semantics) {
                    jstep = '';
                    jStepName = '';
                    jname = 'Suggested Results';
                }
                
                if(status.sn && !status.semantics) {
                    jstep = '';
                    jStepName = '';
                    jname = 'No results found';
                }

                if(!status.sn && !status.semantics) {
                    jstep = fullPath.length;
                    jStepName = status.answerData.title.toLowerCase();
                    jname = (fullPath.length > 1) ? fullPath[1] : fullPath[0]; // may need altering, not sure if they will like 'Root'
                }

                persist_data.SearchLocation = status.ref;

            }

            (route.name == 'whatsnew') && (
                jname = "What's new",
                jStepName = "What's new",
                jstep = 1
            );

            (route.name == 'videos') && (
                jname = "Videos",
                jStepName = "Videos",
                jstep = 1
            );

            (route.name == 'home') && (
                appState = '',
                jstep = ''
            );

            persist_data.JourneyName = jname;
            persist_data.JourneyStep = jstep;
            persist_data.JourneyStepName = jStepName;
            persist_data.ApplicationState = appState;
            persist_data.ProductFamily = prodFamily;
            persist_data.ProductGroup = prodGroup;
            persist_data.ProductSubGroup = prodSubGroup;

            /*
            let persist_data = {
                JourneyName: jname,
                JourneyStep : jstep,
                JourneyStepName : jStepName,
                ApplicationState : appState,
                ProductFamily: prodFamily,
                ProductGroup: prodGroup,
                ProductSubGroup: prodSubGroup
            };
            */

            this.log && console.log('[TRACKING DATA]: %o', persist_data);

            if(typeof window.setAnalyticsVariables === 'function') { // I don't know whether at this point Tealium has finished injecting it's own stuff
                window.setAnalyticsVariables(persist_data, true, "view");
            }
            else {
                setTimeout(() => {
                    window.setAnalyticsVariables(persist_data, true, "view");
                }, 1000);                
            }
        },

        tealiumCarouselNavTracking(tabnumber) {
            let payload = { 
                JourneyEvent: "Carousel Click",
                EventAction: "Button Click",
                EventNarrative: "Navigation",
                EventValue: tabnumber
            };

            let persist = false;
            let send = "event";

            this.log && console.log('[TRACKING DATA][CAROUSEL NAV]: %o', payload);

            if(typeof window.setAnalyticsVariables === 'function') {
                window.setAnalyticsVariables(payload, persist, send);
            }
        },

        tealiumIcsTracking(val) {
            let payload = { 
                JourneyEvent: "Survey Click",
                EventAction: "Button Click",
                EventNarrative: "In conversation survey",
                EventValue: val
            };

            let persist = false;
            let send = "event";

            this.log && console.log('[TRACKING DATA][ICS]: %o', payload);

            if(typeof window.setAnalyticsVariables === 'function') {
                window.setAnalyticsVariables(payload, persist, send);
            }
        },

        tealiumSurveyTracking(val) {
            let payload = { 
                JourneyEvent: "Survey Click",
                EventAction: "Button Click",
                EventNarrative: "Banner Survey/YouTube",
                EventValue: val
            };

            let persist = false;
            let send = "event";

            this.log && console.log('[TRACKING DATA][SURVEY]: %o', payload);

            if(typeof window.setAnalyticsVariables === 'function') {
                window.setAnalyticsVariables(payload, persist, send);
            }
        },

        tealiumGlobalSurveyTracking(type, route, surveyData) {
            console.log(route);
            let payload = {
                JourneyStepName: route.fullPath,
                CanonicalDomainProd: 'cbosupport.lloydsbank.com'
            };

            switch(type) {
                case 'offer':
                    payload.EventAction = 'Survey Offered';
                    payload.EventNarrative = 'Satisfaction Survey';
                    break;

                case 'open':
                    payload.EventAction = 'Survey Opened';
                    payload.EventNarrative = 'Satisfaction Survey';
                    break;

                case 'submit':
                    payload.EventAction = 'Survey Submitted';
                    payload.EventNarrative = 'Satisfaction Survey';

                    payload.QAQuestion1 = surveyData.opt1.title;
                    payload.QAAnswer1 = surveyData.opt1.data;

                    payload.QAQuestion2 = surveyData.opt2.title;
                    payload.QAAnswer2 = surveyData.opt2.data;

                    payload.QAQuestion3 = surveyData.opt3.title;
                    payload.QAAnswer3 = surveyData.opt3.data;
                    break;
            }



            let persist = false;
            let send = "event";

            this.log && console.log('[TRACKING DATA][SURVEY]: %o', payload);

            if(typeof window.setAnalyticsVariables === 'function') {
                window.setAnalyticsVariables(payload, persist, send);
            }

        },

        tealiumInit() {
            window.utag_data = {
                "Brand":"Lloyds",
                "Channel":"Online",
                "Division":"Commercial",
                "Platform":"Unauth",
                "Presentation":"Responsive",
                "State":"Unauth",
                "System":"creativeVirtual",
                "TrustDataLayer": true,
                "TagVersion": "C3.30",
                "TrackEvents": false,
                "TrackHashChanges": false,
                "@context": "{ \"version\": \"1.0\",\"owner\": \"Lloyds Banking Group - Group Web Analytics\"}",
            };
        }
    }
 }


 /*
    
let payload = { 
    JourneyEvent: "Form Click",
    EventAction: "Button Click",
    EventNarrative: "Confirm"
}; 
let persist = false;
let send = "event";

window.setAnalyticsVariables(payload, persist, send);


When it’s necessary to manually push data to Tealium, e.g. when there’s no UI interaction that corresponds to a tracked event, such as the successful return of an AJAX call when the customer authenticates, this can be achieved through the function window.setAnalyticsVariables.

The "payload" object contains assignments of static values to Tealium Universal Data Object variables. These are the same variables as are found in the data layer, and a complete catalogue will be available in the appendix.

The "persist" boolean should be set to "false" to send transient event data, and "true" if the data being passed in the payload needs to be written to the data layer. The latter is a relatively unusual case, except for tracking changes of page within the application.

The "send" string captures the type of event, either "view" for pageviews or "event" for on-page events. In this context, a single page application should fire pageviews each time the entire screen is re-rendered to the customer, regardless of whether the browser has experienced a navigation event.

// nav is only for search and suggested faqs
// titles should be the same case - confirmation of payee

 */