<template>
    <div class="autocomplete" v-if="autocomplete !== null" :class="this.origin">
        <ul>
            <li v-for="(item, index) in autocomplete.AutoComplete" :key="index">
                <a href="#" title="" v-on:click.prevent="submitRequest(item)" :class="{active: index === count}" v-html="highlightFaq(item.QuestionText)"></a>
            </li>
        </ul>
    </div>
</template>

<script>
import router from '../router';
//import store from '../store';

export default {
    name: 'SinglePageSearchAutocompete',
    props: ['autocomplete', 'count', 'query', 'origin'],
    data() {
        return {
            timer: null,
        }
    },
    
    methods: {
        submitRequest: function(faq) {
            router.push({ path: '/search', query: {q: faq.QuestionText }});

            /*
            if(this.isAutocompleteRequestFinished) {
                router.push({ path: 'search', query: {q: faq.QuestionText }});
            }
            else {
                if(this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
                this.timer = setInterval(() => {
                    router.push({ path: 'search', query: {q: faq.QuestionText }});
                }, 100);
            }
            */
        },

        highlightFaq: function(faq) {
            let re = new RegExp("(" + this.query + ")", 'gi');
            let item = faq.replace(re, '<span>$1</span>');
            return item;
        },
    }
}
</script>

<style scoped>
    .autocomplete {
        position: absolute;
        left: 0;
        right: 0;
        border: 1px solid #767676;
        top: 47px;
        background: #ffffff;
        font-size: 1.125em;
        z-index: 100;
    }

    .autocomplete.mainmenu {
        background: #006A4D;
        border-top: 1px solid #00563F;
        border-left: none;
        border-right: none;
        border-bottom: none;
        top: 123px;
    }

    .autocomplete.inline {
        left: -1px;
        right: 139px;
        top: 62px;
    }

    .autocomplete ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .autocomplete ul li a {
        display: block;
        color: #505050;
        line-height: 48px;
        padding: 0 0 0 17px;
        transition: all .1s;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .autocomplete.mainmenu ul li a {
        color: #ffffff;
        padding: 0 0 0 calc((100% - 930px) / 2 + 17px);
    }

    .autocomplete ul li a:hover, .autocomplete ul li a.active {
        background: #006A4D;
        color: #ffffff;
    }

    .autocomplete.mainmenu ul li a:hover, .autocomplete ul li a.active {
        background: #2D8259
    }

    .autocomplete ul li a >>> span {
        font-weight: normal;
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
    }

    @media only screen and (max-width: 929px) {
        .autocomplete.mainmenu ul li a {
            padding: 0 0 0 17px;
        }        
    }
</style>
