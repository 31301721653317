<template>
    <div class="semantics">
        <div class="wrapper">
            <h4>Were you looking for one of the help topics below?</h4>
            <ul>
                <li v-for="(item, index) in semantics" :key="index">
                    <template v-if="item.path.length > 0">
                        <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :data-ansid="item.answer_id" :data-recid="item.recognition_id" :data-category="item.category" @click.prevent="submitRequest(item)">{{item.faqdisplay}}</a>
                    </template>
                    <template v-else>
                        <a :href="'/article/' + titleToLink(item.faqdisplay)" :data-ansid="item.answer_id" :data-recid="item.recognition_id" :data-category="item.category" @click.prevent="submitRequest(item)">{{item.faqdisplay}}</a>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'SinglePageAnswerSemantics',
    props: ['semantics'],
    methods: {
        submitRequest: function(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);
            
            // rf=nav if for Tealium tracking purposes
            faq.path.length > 0 ? router.push({path: '/' + faq.path + '/article/' + title + '?rf=nav'}) : router.push({path: '/article/' + title + '?rf=nav'});

        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    }, 
}
</script>

<style scoped>
    .semantics {
        margin-bottom: 30px;
    }

    .semantics h4 {
        color: #323233;
        font-size: 1.375em;
        font-weight: normal;
    }

    .semantics ul {
        margin: 0;
        padding: 0;
        list-style: none !important;
    }

    .semantics a {
        display: block;
        box-shadow: 0 0 5px 0 rgba(173,173,173,0.5);
        background: #ffffff;
        margin-bottom: 15px;
        position: relative;
        padding: 15px;
        color: #006A4D;
        transition: all .4s;
        font-size: 1.125em;
    }

    .semantics a:hover {
        text-decoration: none;
        background: #F1F1F1;
        padding-left: 30px;
    }

    .semantics a:after {
        border-style: solid;
        border-width: 4px 4px 0 0;
        content: "";
        display: block;
        height: 8px;
        right: 40px;
        position: absolute;
        width: 8px;
        top: 50%;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
    }
</style>
