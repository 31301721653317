<template>
    <div class="bread" :class="{visuallyhidden: this.isCustomPage}">
        <template v-if="answerType != 'Safety Net'">
            <div v-for="(item, index) in path" :key="index" class="out">
                <div class="in">
                    <a :href="createStaticPath(item.navcontext)" title="" :class="{active : path.length == index + 1}" @click.prevent="goTo(item.navcontext)">{{item.displaycontext}}</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="out">
                <div class="in">
                    <a href="/" title="" class="">Root</a>
                </div>
            </div>
            <div class="out">
                <div class="in">
                    <a href="/" title="" class="active">Search results</a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import store from '../store';
import router from '../router';

export default {
    name: 'SinglePageBreadcrumbs',
    computed: {
        path() {
            return store.state.responseData.data.breadcrumb;
        },

        answerType() {
            return store.state.responseData.data.etv.answertype;
        },

        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    },

    methods: {
        goTo(destination) {
            store.dispatch('setCatDestination', destination);
            let url = destination;
            url = url.replace(/Root./g, '');
            url = url.replace(/Root/g, '');
            url = url.toLowerCase();
            url = url.replace(/\./g, '/');
            url = url.replace(/\ /g, '-');
            //console.log(url);
            router.push({path: '/' + url});
        },
        
        createStaticPath(destination) {
            let url = destination;
            url = url.replace(/Root./g, '');
            url = url.replace(/Root/g, '');
            url = url.toLowerCase();
            url = url.replace(/\./g, '/');
            url = url.replace(/\ /g, '-');
            return '/' + url;
        }
    },
}
</script>

<style scoped>

    .bread {
        position: relative;
        z-index: 10;
        display: inline-block;
        margin: 25px 0 30px 41px;
    }

    .bread .out {
        position: relative;
        display: inline-block;
    }

    .bread .out:nth-child(1) { z-index: 50; }
    .bread .out:nth-child(2) { z-index: 40; }
    .bread .out:nth-child(3) { z-index: 30; }
    .bread .out:nth-child(4) { z-index: 20; }
    .bread .out:nth-child(5) { z-index: 10; }

    .bread .in {
        box-shadow: 0 0 5px 0 rgba(80,80,80,0.25);
        position: relative;
        z-index: -1;
    }

    .bread .out:nth-child(1) a {
        border-radius: 50%;
        width: 53px;
        height: 53px;
        box-shadow: 0 0 5px 0 rgba(80,80,80,0.25);
        position: absolute;
        left: -40px;
        top: -32px;
        text-indent: -9999px;
        padding: 0;
        background: #ffffff;
    }

    .bread .out:nth-child(1) a:after {
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: -210px -55px;
        background-size: 300px 300px;
        width: 25px;
        height: 21px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: 10;
        transform: rotate(0deg);
        box-shadow: none;
        background-color: transparent;
    }

    .bread .out:nth-child(1) a:hover {
        background: #EEF4E6;
        text-decoration: none;
    }

    .bread .out:nth-child(1) a:hover:after {
        background-color: transparent;
    }

    .bread .out:nth-child(1) a.active:hover:after {
        background-color: transparent;
    }

    .bread .out:nth-child(1) a.active:hover {
        background: #EEF4E6;
        text-decoration: none;        
    }

    .bread a {
        display: inline-block;
        position: relative;
        background-color: #ffffff;
        height: 35px;
        line-height: 35px;
        padding: 0 25px 0 35px;
        color: #505050;
        transition: all .2s;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .bread a:after {
        content: '';
        display: block;
        position: absolute;
        transition: background-color .2s, color .2s;
        background: #ffffff;
        z-index: -1;
        box-shadow: 0 0 5px 0 rgba(80,80,80,0.25);
        overflow: hidden;
        width: 19px;
        height: 21px;
        transform: rotate(32deg) skew(-1deg, 24deg);
        top: 7px;
        right: -9.6px;
    }

    .bread .out:nth-child(2) a {
        padding: 0 25px 0 29px;
    }

    /*.bread a:hover, .bread a:hover:after, */.bread a.active, .bread a.active:after, .bread a.active:hover, .bread a.active:hover:after {
        background-color: #2D8259;
        text-decoration: none;
        color: #ffffff;
    }

    .bread a:hover, .bread a:hover:after {
        background: #EEF4E6;
        text-decoration: none;
    }

    @media only screen and (max-width: 650px) {

        .bread a {
            text-indent: -9999px;
            padding: 0 10px 0 10px;
            width: 20px;
        }

        .bread .out:nth-child(2) a {
            padding: 0 0 0 0;
        }

        .bread a.active {
            text-indent: 0 !important;
            padding: 0 5px 0 25px !important;
            width: auto !important;
        }    

    }

</style>
