<template>
    <div class="banner">
        <div class="wrapper">
            <div class="links">
                <a href="/webinars" class="ext-link" target="_self">Register now</a>
                <!--<a href="https://resources.lloydsbank.com/cbonlinesupport" class="ext-link" target="_blank" title="Find more help on the current site">Find more help on the current site</a>-->
                <!--<a href="/whats-new" class="ext-link" target="_self" title="See what's new">See what's new</a>-->
            </div>
            <div class="text">
                <p class="strong">NEW: Webinars</p>
                <p>Learn how to get more from Commercial Banking Online in a webinar, including live Q&amp;As with our experts</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>

<style scoped>

    .banner {
        background: #2178B0;
        color: #ffffff;
        padding: 13px 0 13px 0;
        min-height: 75px;
    }

    .banner * {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        position: relative;
    }

    .banner p {
        margin: 0;
        padding: 0;
        font-size: .9375em;
    }

    .banner p.strong {
        font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;
        font-size: 1.125em;
    }

    .banner .links {
        float: right;
        width: 40%;
    }

    .banner .ext-link {
        display: block;
        color: #ffffff;
        position: relative;
        padding-right: 25px;
        text-align: right;
        font-size: 1.125em;
    }

    .banner .ext-link:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        color: #ffffff;
        margin: 0 0 0 5px;      
    }

    @media only screen and (max-width: 966px) {
        .wrapper {
            margin: 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        .banner {
            height: auto;
        }

        .banner .text, .banner .links {
            float: none;
            width: 100%;
        }

        .banner .links {
            margin-bottom: 15px;
        }

        .banner .ext-link {
            text-align: left;
        }
    }

</style>
