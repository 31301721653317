import Vue from 'vue';
import App from './App.vue';
import router from './router';
//import Event from './event';
import store from './store';

//import './assets/js/application.js';

Vue.config.productionTip = false;

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app');
